<template>
  <div class="card-content">
    <CCard>
      <CCardHeader>
        <h4>ポイント設定新規作成</h4>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow>
            <CCol sm="12" md="12" lg="3" class="">
              <label
                class="w-100"
                style="padding-left: 0px; background-color: rgb(213, 213, 213)"
                ><span style="font-weight: bolder">ステップ１:</span>タイトル
                <span class="textMust">（必須）</span></label
              >
            </CCol>
            <CCol sm="12" md="12" lg="9" class="px-4">
              <CInput class="w-100" v-model="title" />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="3" class="">
              <label
                class="w-100"
                style="padding-left: 0px; background-color: rgb(213, 213, 213)"
                ><span style="font-weight: bolder">ステップ２:</span
                >付与ポイント<span class="textMust">（必須）</span></label
              >
            </CCol>
            <CCol sm="12" md="12" lg="9" class="px-4">
              <CInput
                class="w-100"
                type="number"
                v-model="amount"
                maxlength="20"
                min="0"
                onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="3" class="">
              <label
                class="w-100 d-flex align-items-center"
                style="padding-left: 0px; background-color: rgb(213, 213, 213)"
                ><span style="font-weight: bolder">ステップ３:</span>
                ポイント失効設定<span class="textMust">（必須）</span></label
              >
            </CCol>
            <CCol sm="12" md="12" lg="9">
              <div class="w-100">
                <label
                  class="custom-ListLabel"
                  v-bind:class="{ checked: index === isChecked }"
                  v-on:click="ClickContentChecked(index, $event)"
                  v-for="(item, index) in listLabelSettingMail"
                  :key="index"
                  @click="setValueLabel(item.value)"
                >
                  {{ item.text }}
                </label>
                <div class="mt-2" v-if="point_type === 1">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <b-form-radio
                        @change="changeValueRadio(0)"
                        v-model="point_setting"
                        name="some-radios"
                        :value="0"
                        >ポイント付与後</b-form-radio
                      >
                    </div>
                    <div class="col-lg-2">
                      <CInput
                        class="input-hours w-100 mb-0"
                        v-model="point_expiration_value_time"
                        type="number"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        min="0"
                      />
                    </div>
                    <span class="col-auto">時間後に失効する</span>
                  </div>
                  <div class="row align-items-center mt-lg-2 mt-3">
                    <div class="col-auto">
                      <b-form-radio
                        @change="changeValueRadio(1)"
                        v-model="point_setting"
                        name="some-radios"
                        :value="1"
                        >付与後から毎</b-form-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <b-form-select
                        class="w-100"
                        v-model="point_expiration_value"
                        :options="listPointExpirationType"
                      ></b-form-select>
                    </div>
                    <span class="col-auto">1回失効する</span>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="3" class="mt-2 d-flex align-items-center">
              <div class="w-100">
                <label
                  class="w-100"
                  style="
                    padding-left: 0px;
                    background-color: rgb(213, 213, 213);
                  "
                  ><span style="font-weight: bolder">ステップ４:</span>
                  ポイント付与条件選択<span class="textMust"
                    >（必須）</span
                  ></label
                >
              </div>
            </CCol>
            <CCol>
              <div class="w-100 custom-disabled">
                <!-- <b-form-select
                  v-model="action_type"
                  :options="listActionType"
                  @change="changeSelect(action_type)"
                ></b-form-select> -->
                <label
                  class="custom-ListLabel"
                  v-bind:class="{
                    selected: index === isSelected,
                    disabled: $route.params.id !== undefined,
                  }"
                  v-on:click="ClickContentSelected(item.value, index)"
                  v-for="(item, index) in listActionType"
                  :key="index"
                  @click="setPointGranting(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              sm="12"
              md="12"
              lg="3"
              class="mt-4 d-flex align-items-center"
              v-if="this.action_type !== 7"
            >
              <label
                class="w-100"
                style="padding-left: 0px; background-color: rgb(213, 213, 213)"
                ><span style="font-weight: bolder" class="text-nowrap"
                  >ステップ５:</span
                >
                <span v-if="this.action_type === 1"
                  >ポイント付与タイミングを選定<span class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 2"
                  >ポイント付与するカバーを選定する<span class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 3"
                  >ポイント付与するアイテムまたはコンテンツを選択する<span
                    class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 4"
                  >ポイント付与タイミングを選定<span class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 5"
                  >ポイント付与するユーザーを選定する<span class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 8"
                  >ポイント付与タイミングを選定<span class="textMust"
                    >（必須）</span
                  ></span
                >
                <span v-else-if="this.action_type === 9"
                  >ポイント付与するスラグを選定する<span class="textMust"
                    >（必須）</span
                  ></span
                >
              </label>
            </CCol>
            <CCol>
              <div class="w-100 mt-4">
                <div
                  class="align-items-center row"
                  v-if="this.action_type == 1"
                >
                  <CCol sm="12" lg="2" class="">
                    <CInput
                      v-model="repetition_interval"
                      type="text"
                      class="mb-0"
                      min="1"
                      disabled
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    />
                  </CCol>
                  <CCol sm="12" lg="2" class="pr-lg-0">
                    <b-form-select
                      v-model="time_type"
                      :options="listTimeType"
                    ></b-form-select>
                  </CCol>
                  <span class="col-auto pl-lg-0">に</span>
                  <CCol sm="12" lg="2" class="">
                    <CInput
                      v-model="number_received"
                      type="number"
                      class="mb-0"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                    />
                  </CCol>
                  <span class="col-auto pl-lg-1">回付与する</span>
                </div>
                <div
                  class="align-items-center row"
                  v-if="this.action_type == 8"
                >
                  <CCol sm="12" lg="2" class="">
                    <CInput
                      v-model="repetition_interval"
                      type="number"
                      class="mb-0"
                      min="0"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    />
                  </CCol>
                  <CCol sm="12" lg="2" class="pr-lg-0">
                    <b-form-select
                      v-model="time_type"
                      :options="listTimeType"
                    ></b-form-select>
                  </CCol>
                  <span class="col-auto pl-lg-0">に</span>
                  <CCol sm="12" lg="2" class="pr-lg-0">
                    <!-- <CInput
                      v-model="number_received"
                      type="number"
                      class="mb-0"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                    /> -->
                    <CSelect
                      v-if="time_type === 0"
                      v-model="number_received"
                      :options="optionsNumberDay"
                      class="mb-0"
                    />
                    <CSelect
                      v-if="time_type === 1"
                      v-model="number_received"
                      :options="optionsNumberMonth"
                      class="mb-0"
                    />
                    <CSelect
                      v-if="time_type === 2"
                      v-model="number_received"
                      :options="optionsNumberYear"
                      class="mb-0"
                    />
                  </CCol>
                  <span class="col-auto pl-lg-1">回自動付与する</span>
                  <CCol sm="12" class="p-0">
                    <div class="col-sm-12">
                      <label class="mt-3">スラグを選択（スラグ名を入力）</label>
                      <multiselect
                        v-model="slag_type_2"
                        tag-placeholder="閲覧付与スラグを追加してください"
                        class="col-sm-12 col-lg-6 p-0"
                        placeholder="閲覧付与スラグを追加してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        @tag="addTag"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </CCol>
                </div>
                <CCol sm="12" v-if="this.action_type == 2" class="p-0">
                  <div class="w-100">
                    <label
                      class="custom-ListLabel"
                      v-bind:class="{
                        selected: index === isSelectedUser,
                      }"
                      v-on:click="ClickUserSelected(item.value, index)"
                      v-for="(item, index) in optionSetAllContent"
                      :key="index"
                      @click="setAllUser(item.value)"
                    >
                      {{ item.text }}
                    </label>
                    <div v-if="selectAllUser === 0" class="">
                      <label class="mt-3"
                        >カバーアイテムを選択（カバータイトルを入力）</label
                      >
                      <multiselect
                        class="col-sm-12 col-lg-6 p-0"
                        v-model="content_id"
                        placeholder="購入付与スラグ追加"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        style=""
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                        @change="setOptionUser()"
                        @open="clickScrollContent()"
                      ></multiselect>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" v-if="this.action_type == 3" class="p-0">
                  <div class="w-100">
                    <label>販売アイテムを選択（販売タイトルを入力）</label>
                    <multiselect
                      v-model="content_id"
                      class="col-sm-12 col-lg-6 p-0"
                      placeholder="購入付与スラグ追加"
                      label="text"
                      track-by="value"
                      :options="listContentOptions"
                      :multiple="true"
                      :taggable="false"
                      :showNoResults="false"
                      :select-label="'入力して選択する'"
                      :deselect-label="'入力して削除する'"
                      :selected-label="'選択された'"
                      @open="clickScrollContent()"
                      :loading="loadingContent"
                      style=""
                      v-bind:class="{ 'error-input': errorContentIdRequired }"
                    ></multiselect>
                  </div>
                </CCol>
                <CCol sm="12" v-if="this.action_type == 4" class="p-0">
                  <div class="w-100">
                    <label
                      class="custom-ListLabel"
                      v-bind:class="{
                        selected: index === isSelectedContent,
                      }"
                      v-on:click="ClickUserSelected(item.value, index)"
                      v-for="(item, index) in optionAllPurchasers"
                      :key="index"
                      @click="setAllContent(item.value)"
                    >
                      {{ item.text }}
                    </label>
                    <div v-if="isSelectedContent === 0">
                      <label class="mt-3"
                        >販売アイテムを選択（販売タイトルを入力）</label
                      >
                      <multiselect
                        v-model="content_id"
                        placeholder="購入付与スラグ追加"
                        class="col-sm-12 col-lg-6 p-0"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        @open="clickScrollContent()"
                        style=""
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" v-if="this.action_type == 5" class="p-0">
                  <div class="w-100">
                    <label class="mt-3"
                      >ユーザーを選択（ユーザー名及び名前を入力）</label
                    >
                    <multiselect
                      v-model="user_id"
                      tag-placeholder="購入付与スラグ追加"
                      class="col-sm-12 col-lg-6 p-0"
                      placeholder="購入付与スラグ追加"
                      label="text"
                      track-by="value"
                      :options="listUserOptions"
                      :multiple="true"
                      :taggable="false"
                      :showNoResults="false"
                      :select-label="'入力して選択する'"
                      :deselect-label="'入力して削除する'"
                      :selected-label="'選択された'"
                      style=""
                      v-bind:class="{
                        'error-input': errorUserHiddenRequired,
                      }"
                    ></multiselect>
                  </div>
                </CCol>
                <CCol sm="12" v-if="this.action_type == 6" class="p-0">
                  <div class="w-100">
                    <label class="mt-3"></label>
                    <multiselect
                      v-model="survey"
                      tag-placeholder="閲覧付与スラグを追加してください"
                      class="col-sm-12 col-lg-6 p-0"
                      placeholder="閲覧付与スラグを追加してください"
                      label="text"
                      track-by="value"
                      :options="listSurveyOptions"
                      :multiple="true"
                      :taggable="false"
                      :showNoResults="false"
                      @tag="addTag"
                      :max-height="200"
                      select-label="入力して選択する"
                      selected-label="選択された"
                      deselect-label="入力して削除する"
                    >
                      <template v-slot:no-options> リストは空です </template>
                    </multiselect>
                  </div>
                </CCol>
                <CCol sm="12" v-if="this.action_type == 9" class="p-0">
                  <div class="w-100">
                    <label class="mt-3">スラグを選択（スラグ名を入力）</label>
                    <multiselect
                      v-model="slag_type_2"
                      tag-placeholder="閲覧付与スラグを追加してください"
                      class="col-sm-12 col-lg-6 p-0"
                      placeholder="閲覧付与スラグを追加してください"
                      label="slag_name_en"
                      track-by="slag_name_en"
                      :options="listSlagOptions"
                      :multiple="true"
                      :taggable="false"
                      :showNoResults="false"
                      @tag="addTag"
                      :max-height="200"
                      select-label="入力して選択する"
                      selected-label="選択された"
                      deselect-label="入力して削除する"
                    >
                      <template v-slot:no-options> リストは空です </template>
                    </multiselect>
                  </div>
                </CCol>
                <!-- <CCol sm="12" class="mt-3" v-if="this.action_type == 9">
                  <CButton :color="'info'" v-b-modal.modal-add-bonus>
                    Add Bonus
                  </CButton>
                </CCol>
                <CCol sm="12" class="mt-3" v-if="this.action_type == 9">
                  <draggable
                    v-model="bonus"
                    group="people"
                    @start="drag = true"
                    @end="drag = false"
                    class="list-group"
                  >
                    <div
                      v-for="element in bonus"
                      :key="element.id"
                      class="list-group-item"
                    >
                      {{ element.money }}
                    </div>
                  </draggable>
                </CCol> -->
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center">
                <CButton
                  v-on:click.prevent="createNewPoint()"
                  class="mt-3 mx-2 btn-color"
                  variant="success"
                  :size="'lg'"
                >
                  保存
                </CButton>
                <CButton
                  v-on:click.prevent="cancelCreatePoint()"
                  :color="'secondary'"
                  :size="'lg'"
                  class="mt-3 mx-2 back-btn"
                >
                  戻る
                </CButton>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
    <!-- Modal Create Bonus Level -->
    <b-modal id="modal-add-bonus" size="xl" title="章を作成する：" hide-footer>
      <div>
        <CRow>
          <CCol sm="6" class="mt-3">
            <CInput
              label="Money"
              placeholder="Money"
              v-model="money"
              type="number"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              min="0"
            />
          </CCol>
          <CCol sm="6" class="mt-3">
            <CInput
              label="Rate"
              placeholder="Rate"
              v-model="rate"
              min="0"
              type="number"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </CCol>
        </CRow>
      </div>
      <b-button variant="info" v-on:click.prevent="createBonus()"
        >Add Bonus</b-button
      >
    </b-modal>
    <!-- Modal Create Bonus Level -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
// import draggable from "vuedraggable";
export default {
  name: "CreatePoint",
  data() {
    return {
      title: "",
      amount: 1,
      action_type: 1,
      listActionType: [
        { text: "ログイン", value: 1 },
        { text: "新規登録", value: 2 },
        { text: "アイテムクリック後", value: 3 },
        { text: "購入後", value: 4 },
        { text: "個別に付与", value: 5 },
        { text: "アンケート回答後", value: 6 },
        { text: "プロファイル記入後", value: 7 },
        { text: "自動付与", value: 8 },
        { text: "スラグ付与後", value: 9 },
      ],
      point_expiration_type: "",
      listPointExpirationType: [
        { text: "日", value: 0 },
        { text: "月", value: 1 },
        { text: "年", value: 2 },
      ],
      point_expiration_value: null,
      time_type: 1,
      listTimeType: [
        { text: "日", value: 0 },
        { text: "ヶ月", value: 1 },
        { text: "年", value: 2 },
      ],
      repetition_interval: 1,
      isLoading: false,
      number_received: 1,
      listAll: [],
      listContentOptions: [],
      content_id: [],
      listContentId: [],
      user_id: [],
      listUserOptions: [],
      listUserId: [],
      listAllUser: [],
      money: null,
      rate: null,
      bonus_by_level: [],
      bonus: [],
      page: 2,
      errorContentIdRequired: false,
      loadingContent: false,
      userHidden: [],
      errorUserHiddenRequired: false,
      isChecked: 0,
      point_type: 0,
      listLabelSettingMail: [
        { value: 0, text: "設定しない" },
        { value: 1, text: "設定" },
      ],
      optionSetAllContent: [
        { value: 0, text: "一部の登録者に付与" },
        { value: 1, text: "全ての登録者に付与" },
      ],
      optionAllPurchasers: [
        { value: 0, text: "一部の購入者に付与" },
        { value: 1, text: "全ての購入者に付与" },
      ],
      point_setting: 0,
      isSelected: 0,
      point_granting: 0,
      selectAllUser: 1,
      isSelectedUser: 0,
      listSlagOptions: [],
      all_user: 0,
      all_content: 0,
      slag_type_2: "",
      listcontent: [],
      listUserPoint: [],
      listSlagPoint: [],
      check: false,
      isSelectedContent: 0,
      point_expiration_value_time: "",
      optionsNumberDay: [{ value: 1, text: "1" }],
      optionsNumberMonth: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
      ],
      optionsNumberYear: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 12, text: "12" },
      ],
      listSurveyOptions: [],
      survey: "",
      slag_type_1: null,
      listSurveys: [],
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    // draggable,
  },
  created() {
    const { params } = this.$route;
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    const dataRequest = {
      id: params.id,
      shop_id: this.shop_id,
    };
    this.getSettingPointById(dataRequest);
    this.getListContent(requestContent);
    this.getListUser(requestContent);
    this.getALLlistSlag(requestContent.data);
    this.getListSurvey(requestContent);
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listContent",
      "listUser",
      "listSlag",
      "settingPointById",
      "listSurvey",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.push({
          name: this.$route.params.shopId ? "list point" : "list point domain",
        });
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    content_id() {},
    listContent() {
      let listContent = [];
      listContent = this.listContent.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContent.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
    user_id() {},
    listUser() {
      this.listUserOptions = this.listUser.data.map((item) => ({
        value: item.id,
        text: item.email,
      }));
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    listSurvey() {
      this.listSurveyOptions = this.listSurvey.data.map((item) => ({
        value: item.id,
        text: item.survey_title,
      }));
    },
    settingPointById() {
      this.title = this.settingPointById.point_title;
      this.amount = this.settingPointById.point_amount;
      this.point_type = this.settingPointById.point_expiration_type;
      this.isChecked = this.settingPointById.point_expiration_type;
      this.point_setting = this.settingPointById.point_expiration_setting;
      this.action_type = this.settingPointById.action_type;
      this.isSelected = this.settingPointById.action_type - 1;
      if (this.settingPointById.point_expiration_setting === 0) {
        this.point_expiration_value_time =
          this.settingPointById.point_expiration_setting_value;
      } else {
        this.point_expiration_value =
          this.settingPointById.point_expiration_setting_value;
      }
      if (this.settingPointById.bonus_by_level !== null) {
        this.repetition_interval = JSON.parse(
          this.settingPointById.bonus_by_level
        ).repetition_interval;
        this.time_type = JSON.parse(
          this.settingPointById.bonus_by_level
        ).time_type;
        this.number_received = JSON.parse(
          this.settingPointById.bonus_by_level
        ).number_received;
        this.slag_type_1 = JSON.parse(
          this.settingPointById.bonus_by_level
        ).slag_id;
      }
      if (this.settingPointById.all_user === 1) {
        this.isSelectedUser = 1;
        this.selectAllUser = 1;
      } else {
        this.isSelectedUser = 0;
        this.selectAllUser = 0;
      }
      if (this.settingPointById.all_content === 1) {
        this.isSelectedContent = 1;
        this.isSelectedContent = 1;
      } else {
        this.isSelectedContent = 0;
        this.isSelectedContent = 0;
      }
      if (this.settingPointById.contents !== undefined) {
        this.settingPointById.contents = this.settingPointById.contents.map(
          (item) => ({
            value: item.id,
            text: item.title,
          })
        );
      }
      this.content_id = this.settingPointById.contents;
      if (this.settingPointById.users !== undefined) {
        this.settingPointById.users = this.settingPointById.users.map(
          (item) => ({
            value: item.id,
            text: item.email,
          })
        );
      }
      if (this.settingPointById.slags !== undefined) {
        this.settingPointById.slags = this.settingPointById.slags.map(
          (item) => ({
            slag_id: item.id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      this.slag_type_2 = this.settingPointById.slags;
      this.user_id = this.settingPointById.users;
      this.dataPoint = this.settingPointById;
      this.bonus = JSON.parse(this.settingPointById.bonus_by_level);
    },
  },
  methods: {
    ...mapActions({
      createPoint: "createPoint",
      getListContent: "getListContent",
      getListUser: "getListUser",
      getALLlistSlag: "getALLlistSlag",
      getSettingPointById: "getSettingPointById",
      updatePointById: "updatePointById",
      getListSurvey: "getListSurvey",
    }),
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContent(requestContent);
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    createNewPoint() {
      const id = this.$route.params.id;
      if (this.action_type == 1) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          bonus_by_level: {
            repetition_interval: this.repetition_interval,
            time_type: this.time_type,
            number_received: this.number_received,
          },
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 2) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.selectAllUser === 0) {
          this.all_user = 0;
        } else {
          this.all_user = 1;
        }
        if (this.selectAllUser === 0) {
          if (this.content_id !== null) {
            this.content_id.map((item) => {
              this.listcontent.push(item.value);
            });
          }
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          content_id: this.listcontent,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          all_user: this.all_user,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 3) {
        this.listcontent = [];
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.selectAllUser === 0) {
          if (this.content_id !== null) {
            this.content_id.map((item) => {
              this.listcontent.push(item.value);
            });
          }
        }
        // if (this.listAll.length == this.content_id.length) {
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          content_id: this.listcontent,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
        // }
        // else if (this.listAll.length != this.content_id.length) {
        //   this.content_id.map((item) => {
        //     this.listContentId.push(item.value);
        //   });
        //   const formData = {
        //     shop_id: shop_id,
        //     point_title: this.title,
        //     point_amount: this.amount,
        //     action_type: this.action_type,
        //     point_expiration_type: this.point_expiration_type,
        //     point_expiration_value: this.point_expiration_value,
        //     all_content: false,
        //     contents: this.listContentId,
        //   };
        //   this.createPoint(formData);
        // }
      } else if (this.action_type == 4) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        // if (this.listAll.length == this.content_id.length) {
        // this.content_id.map((item) => {
        //   this.listContentId.push(item.value);
        // });
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.isSelectedContent === 1) {
          this.all_content = 1;
        } else {
          this.all_content = 0;
        }
        if (this.selectAllUser === 0) {
          if (this.content_id !== null) {
            this.content_id.map((item) => {
              this.listcontent.push(item.value);
            });
          }
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          content_id: this.listcontent,
          all_content: this.all_content,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
        // } else if (this.listAll.length != this.content_id.length) {
        //   this.content_id.map((item) => {
        //     this.listContentId.push(parseInt(item.value));
        //   });
        //   const formData = {
        //     shop_id: shop_id,
        //     point_title: this.title,
        //     point_amount: this.amount,
        //     action_type: this.action_type,
        //     point_expiration_type: this.point_expiration_type,
        //     point_expiration_value: this.point_expiration_value,
        //     all_content: false,
        //     list_content: this.listContentId,
        //   };
        //   this.createPoint(formData);
        // }
      } else if (this.action_type == 5) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        // if (this.listAllUser.length != this.user_id.length) {
        if (this.user_id !== null) {
          this.user_id.map((item) => {
            this.listUserId.push(item.value);
          });
        }
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.user_id !== null) {
          this.user_id.map((item) => {
            this.listUserPoint.push(item.value);
          });
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          user_id: this.listUserPoint,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
        // } else if (this.listAllUser.length == this.user_id.length) {
        //   const formData = {
        //     shop_id: shop_id,
        //     point_title: this.title,
        //     point_amount: this.amount,
        //     action_type: this.action_type,
        //     point_expiration_type: this.point_expiration_type,
        //     point_expiration_value: this.point_expiration_value,
        //     all_user: true,
        //   };
        //   this.createPoint(formData);
        // }
      } else if (this.action_type == 6) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        // if (this.listAllUser.length != this.user_id.length) {
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.survey !== null) {
          this.survey.map((item) => {
            this.listSurveys.push(item.value);
          });
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          survey_id: this.listSurveys,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 6) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.slag_type_2 !== null) {
          this.slag_type_2.map((item) => {
            this.listSlagPoint.push(item.slag_id);
          });
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_expiration_type,
          point_expiration_value: this.point_expiration_value,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 7) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          all_user: 1,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 8) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.slag_type_2 !== null) {
          this.slag_type_2.map((item) => {
            this.listSlagPoint.push(item.slag_id);
          });
        }
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          bonus_by_level: {
            repetition_interval: this.repetition_interval,
            time_type: this.time_type,
            number_received: this.number_received,
          },
          slag_id: this.listSlagPoint,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      } else if (this.action_type == 9) {
        this.isLoading = true;
        let shop_id = this.shop_id;
        if (this.point_type === 0) {
          this.point_setting = null;
          this.point_expiration_value = null;
        }
        if (this.slag_type_2 !== null) {
          this.slag_type_2.map((item) => {
            this.listSlagPoint.push(item.slag_id);
          });
        }
        // if (this.point_expiration_type == 0) {
        const formData = {
          id: id,
          shop_id: shop_id,
          point_title: this.title,
          point_amount: this.amount,
          action_type: this.action_type,
          point_expiration_type: this.point_type,
          point_expiration_setting: this.point_setting,
          point_expiration_setting_value:
            this.point_setting == 0
              ? this.point_expiration_value_time
              : this.point_expiration_value,
          slag_id: this.listSlagPoint,
        };
        if (id === null || id === undefined) {
          this.createPoint(formData);
        } else {
          this.updatePointById(formData);
        }
      }
    },
    cancelCreatePoint() {
      this.$router.push({ name: "list point" });
    },
    createBonus() {
      if (this.money !== "" || this.rate !== "") {
        this.bonus.push({
          money: this.money,
          rate: this.rate,
        });
        this.showBtnView = true;

        this.$toasted.success("正常に登録");
        this.money = "";
        this.rate = "";
        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-bonus");
        });
      } else {
        this.$toasted.error("登録に失敗しました");
      }
    },
    ClickContentChecked(index) {
      if (this.isChecked === index) {
        this.isChecked = 1;
      } else {
        this.isChecked = index;
        this.isActive = null;
      }
    },
    setValueLabel(value) {
      this.point_type = value;
    },
    setAllUser(value) {
      this.selectAllUser = value;
    },
    setPointGranting(value) {
      this.content_id = null;
      this.user_id = null;
      this.slag_type_2 = null;
      this.selectAllUser = 0;
      this.listcontent = [];
      this.isSelectedUser = 0;
      this.action_type = value;
    },
    ClickContentSelected(value, index) {
      if (this.isSelected === index) {
        this.isSelected = null;
      } else {
        this.isSelected = index;
        this.isActive = null;
      }
    },
    ClickUserSelected(value, index) {
      if (this.isSelectedUser === index) {
        this.isSelectedUser = null;
      } else {
        this.isSelectedUser = index;
      }
      if (this.isSelectedContent === index) {
        this.isSelectedContent = null;
      } else {
        this.isSelectedContent = index;
      }
    },
    setOptionUser() {
      this.listContent();
    },
    changeValueRadio(id) {
      if (id === 1) {
        this.point_expiration_value_time = "";
      } else {
        this.point_expiration_value = "";
      }
    },
    setAllContent(value) {
      this.isSelectedContent = value;
    },
    clickScrollContent() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollContent);
    },
  },
};
</script>
<style scoped>
.input-hours {
  margin-bottom: 0rem;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
</style>
